import React from 'react';
import { Button } from 'react-admin';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function DownloadFileBtn(props) {
    const handleClick = event => {
        event.preventDefault();
        const { record } = props;
        console.log(record);
        fetch(record.download_url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then(response => response.blob())
            .then(blob => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.setAttribute('download', `qrcodes-${record._id}.pdf`);
                if (link) {
                    link.href = url;

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    if (link.parentNode) link.parentNode.removeChild(link);
                }
            });
    };
    return (
        <>
            <Button onClick={handleClick} label="PDF">
                <CloudDownloadIcon />
            </Button>
        </>
    );
}

export default DownloadFileBtn;
