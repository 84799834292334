import React, { useState } from 'react';
import { Button, SaveButton, useUpdate, FormWithRedirect } from 'react-admin';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography, Chip } from '@material-ui/core';

function UseCouponBtn(props) {
    const { record } = props;
    const is_used = record ? record.is_used : 0;
    const [showDialog, setShowDialog] = useState(false);
    const [update, { loading }] = useUpdate();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        update(
            'coupons-users',
            record.id,
            { is_used: true, used_at: new Date() },
            record
        );
    };

    return is_used ? (
        <Chip size="small" label="Utilisé" variant="outlined" />
    ) : (
        <>
            <Button
                onClick={handleClick}
                label="resources.coupons_users.actions.use"
            >
                <IconSave />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Use Coupon"
            >
                <DialogTitle>Utiliser Coupon</DialogTitle>

                <FormWithRedirect
                    resource="coupons-users"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving,
                    }) => (
                        <>
                            <DialogContent>
                                <Typography>
                                    Etes vous sùre de vouloire rendre ce gain
                                    utilisé?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default UseCouponBtn;
