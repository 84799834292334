import * as React from 'react';
import { FC } from 'react';
import { Create, TextField } from 'react-admin';

import { Product, FieldProps } from '../types';
import ProductForm from './ProductForm';

const ProductCreate = (props: any) => {
    return (
        <Create title={<ProductTitle />} component="div" {...props}>
            <ProductForm />
        </Create>
    );
};

const ProductTitle: FC<FieldProps<Product>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

export default ProductCreate;
