import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Passe',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions: {
                title: "Nombre d'inscriptions",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            products: {
                title: 'Nombre de Produits ',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            coupons: {
                title: 'Nombre de Coupons',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            ca: {
                title: "Chiffre d'Affaire en DH",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },

            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items: 'par %{customer_name}, un produit |||| par %{customer_name}, %{nb_items} produits',
            },
            welcome: {
                title: 'Bienvenue dans OLA',
                subtitle: 'Ceci est le back-office OLA..',
                aor_button: 'Site web de OLA',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
        actions: {
            concel: 'Annuler',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom Complet',
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                name: {
                    first: 'Prénom',
                    last: 'Nom',
                },
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        banners: {
            name: 'Bannière |||| Bannières',
            fields: {
                name: 'Nom',
                image: 'Image',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                title: 'Titre',
                description: 'Description',
                parent: 'Parent',
                image: 'Image',
                count_products: 'Nombre Produits',
                created_at: 'Date de Création',
            },
        },
        products: {
            name: 'Produit |||| Produits',
            fields: {
                title: 'Titre',
                description: 'Description',
                price: 'Prix',
                points: 'Points',
                parent: 'Catégorie',
                image: 'Image',
                category: 'Catégorie',
                is_published: 'Est Publié',
                created_at: 'Date de Création',
                qrcodes_summary: {
                    generated: 'QR Codes générés',
                    scanned: 'Nombre de scans',
                    not_scanned: 'QR Codes non scannés',
                    printed: 'QR Codes imprimés',
                    not_printed: 'QR Codes non imprimés',
                },
            },
            actions: {
                generate_qrcodes: 'Génerer QR Codes',
                print_qrcodes: 'Imprimer',
                delete_bulk: 'Supprimer',
                unscan_qrcode: 'Dévalider',
            },
        },
        qrcodes: {
            name: 'QR Code |||| QR Codes',
            fields: {
                points: 'Points',
                image: 'Image',
                is_scanned: 'Est scanné',
                is_printed: 'Est imprimé',
                scanned_at: 'Scanné le',
                scanned_by: 'Scanné par',
                product: 'Produit',
                created_at: 'Date de Géneration',
            },
            generate: {
                action: 'Génerer',
                quantity: 'Nombre de QR Codes',
            },
        },
        qrcodes_prints: {
            name: 'Impression QR Codes |||| Impressions QR Codes',
            fields: {
                product: 'Produit',
                qrcodes_count: 'Nombre QR Codes',
                created_at: 'Date de Géneration',
            },
        },
        coupons: {
            name: 'Coupon |||| Coupons',
            fields: {
                title: 'Titre',
                description: 'Description',
                points: 'Points',
                is_enabled: 'Est activé',
                is_rondom_choosable: 'Peut être tiré au sort',
                image: 'Image',
                created_at: 'Date de Création',
                used_at: 'Utilisé le',
                user: 'Client',
            },
            actions: {
                enable: 'Activer',
                disable: 'Désactiver',
            },
        },
        coupons_users: {
            name: 'Gain |||| Historique Gains',
            fields: {
                user: 'Client',
                coupon: 'Coupon',
                code: 'Code',
                is_used: 'Est utilisé',
                used_at: 'Utilisé le',
                title: 'Titre',
                description: 'Description',
                points: 'Points',
                is_enabled: 'Est activé',
                is_rondom_choosable: 'Peut être tiré au sort',
                image: 'Image',
                created_at: 'Date de Création',
            },
            actions: {
                use: 'Utiliser',
            },
        },
        scans: {
            name: 'Scan |||| Historique Scans',
            fields: {
                points: 'Points',
                image: 'Image',
                is_scanned: 'Est scanné',
                is_printed: 'Est imprimé',
                scanned_at: 'Scanné le',
                scanned_by: 'Scanné par',
                product: 'Produit',
                created_at: 'Date de Géneration',
            },
        },
    },
};

export default customFrenchMessages;
