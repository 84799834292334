import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, MenuItemLink, DashboardMenuItem } from 'react-admin';

import users from '../users';
import admins from '../admins';
import categories from '../categories';
import products from '../products';
import coupons from '../coupons';
import coupons_users from '../coupons-users';
import scans from '../scans';

// import DashboardMenuItem from './DashboardMenuItem';

import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuDelivery';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />

            <MenuItemLink
                to={`/categories`}
                primaryText={translate(`resources.categories.name`, {
                    smart_count: 2,
                })}
                leftIcon={<categories.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/products`}
                primaryText={translate(`resources.products.name`, {
                    smart_count: 2,
                })}
                leftIcon={<products.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/scans`}
                primaryText={translate(`resources.scans.name`, {
                    smart_count: 2,
                })}
                leftIcon={<scans.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/coupons`}
                primaryText={translate(`resources.coupons.name`, {
                    smart_count: 2,
                })}
                leftIcon={<coupons.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/coupons-users`}
                primaryText={translate(`resources.coupons_users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<coupons_users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/admins`}
                primaryText={translate(`resources.admins.name`, {
                    smart_count: 2,
                })}
                leftIcon={<admins.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
