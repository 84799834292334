import * as React from 'react';
import { FC } from 'react';
import { Create, TextField } from 'react-admin';

import { Coupon, FieldProps } from '../types';
import CouponForm from './CouponForm';

const CouponCreate = (props: any) => {
    return (
        <Create title={<CouponTitle />} component="div" {...props}>
            <CouponForm />
        </Create>
    );
};

const CouponTitle: FC<FieldProps<Coupon>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

export default CouponCreate;
