import React from 'react';
import { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    ReferenceField,
    Filter,
    List,
    ReferenceInput,
    SearchInput,
    AutocompleteInput,
    EditButton,
    DeleteWithConfirmButton,
    downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import Thumbnailfield from '../fields/common/ThumbnailField';
import PriceField from '../fields/common/PriceField';
import BulkDeleteButton from './btns/BulkDeleteButton';

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'category', 'categories').then(categories => {
        const data = records.map(record => ({
            titre: record.title,
            categorie: categories[record.category].title,
            prix: record.price,
            points: record.points,
            nombre_scans: record.qrcodes_summary.scanned,
            cree_le: new Date(record.created_at).toLocaleDateString('fr-FR', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }),
        }));
        return jsonExport(
            data,
            {
                headers: [
                    'titre',
                    'categorie',
                    'prix',
                    'points',
                    'nombre_scans',
                    'cree_le',
                ],
            },
            (err, csv) => {
                downloadCSV(csv, 'produits');
            }
        );
    });
};
const ProductFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput
            source="category"
            label="resources.products.fields.category"
            reference="categories"
            alwaysOn
        >
            <AutocompleteInput optionText="title" emptyText="Tout" resettable />
        </ReferenceInput>
    </Filter>
);
const ProductBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton label="" {...props} />
    </Fragment>
);
const isSelectable = record => {
    return !record.count_generated_qrcodes;
};
const ProductList = (props: any) => {
    return (
        <List
            {...props}
            filters={<ProductFilter />}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={exporter}
            bulkActionButtons={<ProductBulkActionButtons />}
        >
            <Datagrid optimized rowClick="show" isRowSelectable={isSelectable}>
                <Thumbnailfield source="image" />
                <TextField
                    source="title"
                    label="resources.products.fields.title"
                />
                <ReferenceField
                    source="category"
                    label="resources.products.fields.category"
                    reference="categories"
                >
                    <TextField source="title" />
                </ReferenceField>
                <PriceField
                    source="price"
                    label="resources.products.fields.price"
                />
                <NumberField
                    source="points"
                    label="resources.products.fields.points"
                />
                <NumberField
                    source="qrcodes_summary.generated"
                    label="resources.products.fields.qrcodes_summary.generated"
                />
                <NumberField
                    source="qrcodes_summary.scanned"
                    label="resources.products.fields.qrcodes_summary.scanned"
                />
                <NumberField
                    source="qrcodes_summary.not_scanned"
                    label="resources.products.fields.qrcodes_summary.not_scanned"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.products.fields.created_at"
                />
                <EditButton />
                <DeleteWithConfirmButton confirmTitle={'Supprimer Produit'} />
            </Datagrid>
        </List>
    );
};

export default ProductList;
