import React from 'react';
import {
    Datagrid,
    ReferenceField,
    DateField,
    TextField,
    Filter,
    List,
    ReferenceInput,
    AutocompleteInput,
    downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import FullNameField from '../fields/users/FullNameField';
import CountryInput from '../inputs/CountryInput';
import QrCodeUnScanButton from '../products/btns/QrCodeUnscanButton';

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'product', 'products').then(products => {
        fetchRelatedRecords(records, 'scanned_by', 'users').then(users => {
            const data = records.map(record => ({
                id: record.id,
                produit: products[record.product].title,
                scanne_par: users[record.scanned_by].name,
                pays: record.scanned_by_info.country,
                cree_le: new Date(record.created_at).toLocaleDateString(
                    'fr-FR',
                    {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                    }
                ),
            }));
            return jsonExport(
                data,
                {
                    headers: ['id', 'produit', 'scanne_par', 'pays', 'cree_le'],
                },
                (err, csv) => {
                    downloadCSV(csv, 'scans');
                }
            );
        });
    });
};

const QrCodeFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput
            source="scanned_by"
            label="resources.scans.fields.scanned_by"
            reference="users"
            alwaysOn
        >
            <AutocompleteInput optionText="name" emptyText="Tout" resettable />
        </ReferenceInput>

        <ReferenceInput
            source="product"
            label="resources.scans.fields.product"
            reference="products"
            alwaysOn
        >
            <AutocompleteInput optionText="title" emptyText="Tout" resettable />
        </ReferenceInput>
        <CountryInput
            source="scanned_by_info.country"
            label="resources.users.fields.country"
            emptyText="Tout"
            resettable
            alwaysOn
        />
    </Filter>
);
const CouponUsersList = (props: any) => {
    return (
        <List
            tltle="ra"
            {...props}
            basePath="/qrcodes"
            resource="qrcodes"
            hasCreate={false}
            filters={<QrCodeFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            filter={{ is_scanned: true }}
            perPage={10}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick={false}>
                <ReferenceField
                    label="resources.scans.fields.product"
                    source="product"
                    reference="products"
                >
                    <TextField source="title" />
                </ReferenceField>
                <ReferenceField
                    label="resources.scans.fields.scanned_by"
                    source="scanned_by"
                    reference="users"
                >
                    <FullNameField />
                </ReferenceField>
                <TextField
                    source="scanned_by_info.country"
                    label="resources.users.fields.country"
                />
                <DateField
                    source="scanned_at"
                    type="date"
                    label="resources.scans.fields.scanned_at"
                    showTime
                />
                <QrCodeUnScanButton />
            </Datagrid>
        </List>
    );
};

export default CouponUsersList;
