import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    Filter,
    List,
    SearchInput,
} from 'react-admin';
import Thumbnailfield from '../fields/common/ThumbnailField';
const PcFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
const CategoryList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PcFilter />}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <Thumbnailfield source="image" />
                <TextField
                    source="title"
                    label="resources.categories.fields.title"
                />
                <NumberField
                    source="count_products"
                    label="resources.categories.fields.count_products"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.categories.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default CategoryList;
