import * as React from 'react';
import { FC } from 'react';
import { Customer, FieldProps } from '../../types';

const API_URL = process.env.REACT_APP_REST_API_URL;
interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const QrCodeImageField: FC<Props> = ({
    record,
    source = 'id',
    size = '25',
    className,
}) =>
    record ? (
        <img
            alt=""
            src={`${API_URL}/admin/qrcodes/${record[source]}/view`}
            style={{ height: parseInt(size, 16) }}
            className={className}
        />
    ) : null;

export default QrCodeImageField;
