import * as React from 'react';
import {
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
    minValue,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import MyImageInput from '../inputs/MyImageInput';

const ProductForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(30)];
    const validatePrice = [required(), minValue(0)];
    const validatePoints = [required(), minValue(0)];
    const validateCategory = [required()];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box flex={2} display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="title"
                                        source="title"
                                        label="resources.products.fields.title"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <Box display="flex">
                                        <NumberInput
                                            label="resources.products.fields.price"
                                            source="price"
                                            validate={validatePrice}
                                        />
                                        <NumberInput
                                            label="resources.products.fields.points"
                                            source="points"
                                            validate={validatePoints}
                                        />
                                        <ReferenceInput
                                            label="resources.products.fields.category"
                                            source="category"
                                            reference="categories"
                                            allowEmpty
                                            validate={validateCategory}
                                        >
                                            <SelectInput
                                                optionText="title"
                                                allowEmpty
                                            />
                                        </ReferenceInput>
                                    </Box>

                                    <TextInput
                                        type="description"
                                        source="description"
                                        resource="categories"
                                        label="resources.products.fields.description"
                                        multiline
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                                    <MyImageInput
                                        source="image"
                                        label="resources.products.fields.image"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="products"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default ProductForm;
