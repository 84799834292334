import * as React from 'react';
import {
    TextInput,
    NumberInput,
    BooleanInput,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
    minValue,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import MyImageInput from '../inputs/MyImageInput';

const CouponForm = (props: any) => {
    const validateName = [required(), minLength(1), maxLength(100)];
    const validatePoints = [required(), minValue(0)];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box flex={2} display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="title"
                                        source="title"
                                        label="resources.coupons.fields.title"
                                        validate={validateName}
                                        fullWidth
                                    />
                                    <NumberInput
                                        label="resources.coupons.fields.points"
                                        source="points"
                                        validate={validatePoints}
                                    />
                                    <BooleanInput
                                        label="resources.coupons.fields.is_enabled"
                                        source="is_enabled"
                                    />

                                    <TextInput
                                        type="description"
                                        source="description"
                                        label="resources.coupons.fields.description"
                                        multiline
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                                    <MyImageInput
                                        source="image"
                                        label="resources.coupons.fields.image"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="coupons"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default CouponForm;
