import * as React from 'react';
import { FC } from 'react';
import { Edit, TextField } from 'react-admin';
import { Coupon, FieldProps } from '../types';
import Aside from './Aside';
import CouponForm from './CouponForm';
const CouponEdit = (props: any) => {
    return (
        <Edit
            title={<CouponTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <CouponForm />
        </Edit>
    );
};

const CouponTitle: FC<FieldProps<Coupon>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

export default CouponEdit;
