import * as React from 'react';
import { FC } from 'react';
import { Show, ReferenceField, DateField, TextField } from 'react-admin';

import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { Typography } from '@material-ui/core';
import { Product, FieldProps } from '../types';

const Title: FC<FieldProps<Product>> = ({ record }) =>
    record ? <Typography>Produit</Typography> : null;

/**
 * Show Component
 */
const ProductShow = (props: any) => {
    return (
        <Show {...props} component="div" tltle={<Title />}>
            <BoxedShowLayout>
                <RaBox display="flex" flexWrap="wrap">
                    <TextField
                        addLabel={true}
                        label="resources.products.fields.title"
                    />
                    <DateField
                        source="created_at"
                        addLabel={true}
                        label="resources.products.fields.created_at"
                    />
                    <ReferenceField
                        label="resources.products.fields.category"
                        source="category"
                        reference="categories"
                    >
                        <TextField source="title" />
                    </ReferenceField>
                </RaBox>
            </BoxedShowLayout>
        </Show>
    );
};

export default ProductShow;
