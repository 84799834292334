import React, { useState, useEffect } from 'react';
import {
    useDataProvider,
    AutocompleteInput,
    Loading,
    Error,
} from 'react-admin';
interface State {
    countries: Array<Object>;
}
const toChoices = countries =>
    countries
        ? countries.map(c => ({
              id: c.name,
              name: c.name,
          }))
        : [];
const CountryInput = props => {
    const dataProvider = useDataProvider();
    const [countries, setCountries] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        dataProvider
            .getList('countries', {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 200 },
            })
            .then(({ data }) => {
                setCountries(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [dataProvider]);

    if (loading) return <Loading />;
    if (error) return <Error />;

    return countries ? (
        <AutocompleteInput
            {...props}
            choices={toChoices(countries)}
            suggestionLimit={5}
        />
    ) : null;
};

export default CountryInput;
