import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import List from './CouponUserList';
import Show from './CouponUserShow';

export default {
    list: List,
    show: Show,
    icon: MonetizationOnIcon,
};
