import React from 'react';
import { Datagrid, DateField, List, useRecordContext } from 'react-admin';
import DownloadFileBtn from './btns/DownloadFileBtn';
import ExportPrintCsvBtn from './btns/ExportPrintCsvBtn';
const ProductQrCodesPrintList = (props: any) => {
    const record = useRecordContext(props);
    return (
        <List
            tltle="ra"
            {...props}
            basePath="/qrcodes_prints"
            resource="qrcodes_prints"
            hasCreate={false}
            filters={false}
            sort={{ field: 'created_at', order: 'DESC' }}
            filter={{ product: record.id }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick={false}>
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.qrcodes_prints.fields.created_at"
                />
                <DownloadFileBtn />
                <ExportPrintCsvBtn />
            </Datagrid>
        </List>
    );
};

export default ProductQrCodesPrintList;
