import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    NumberField,
    ReferenceField,
    Filter,
    List,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    useRecordContext,
} from 'react-admin';
import FullNameField from '../fields/users/FullNameField';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';
import QrCodeImageField from '../fields/qrcodes/QrCodeImageField';
import PrintAndDownloadOneQrCodeBtn from '../qrcodes/btns/PrintAndDownloadOneQrCodeBtn';
import QrCodeUnScanButton from './btns/QrCodeUnscanButton';

const QrCodeFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput
            source="scanned_by"
            label="resources.qrcodes.fields.scanned_by"
            reference="users"
            alwaysOn
        >
            <AutocompleteInput optionText="name" emptyText="Tout" resettable />
        </ReferenceInput>
        <BooleanInput
            source="is_scanned"
            label="resources.qrcodes.fields.is_scanned"
        />
    </Filter>
);
const ProductQrCodeList = (props: any) => {
    const record = useRecordContext(props);
    return (
        <List
            tltle="ra"
            {...props}
            basePath="/qrcodes"
            resource="qrcodes"
            hasCreate={false}
            filters={<QrCodeFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            filter={{ product: record.id }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick={false}>
                <QrCodeImageField />
                <TextField label="Code" source="uuid" />
                <NumberField
                    source="points"
                    label="resources.qrcodes.fields.points"
                />
                <ColoredBooleanField
                    label="resources.qrcodes.fields.is_printed"
                    source="is_printed"
                />
                <ColoredBooleanField
                    label="resources.qrcodes.fields.is_scanned"
                    source="is_scanned"
                />
                <ReferenceField
                    label="resources.qrcodes.fields.scanned_by"
                    source="scanned_by"
                    reference="users"
                >
                    <FullNameField />
                </ReferenceField>
                <DateField
                    source="scanned_at"
                    type="date"
                    label="resources.qrcodes.fields.scanned_at"
                    showTime
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.qrcodes.fields.created_at"
                    showTime
                />
                <PrintAndDownloadOneQrCodeBtn />
                <QrCodeUnScanButton />
            </Datagrid>
        </List>
    );
};

export default ProductQrCodeList;
