import React, { useState } from 'react';
import { Button, useNotify, useRefresh, useUpdateMany } from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function BulkDeleteButton({ selectedIds }) {
    const [showDialog, setShowDialog] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const data = selectedIds.map(id => {
        return { id, is_deleted: true };
    });
    const [updateMany, { loading }] = useUpdateMany(
        'products/bulk',
        selectedIds,
        data,
        {
            onSuccess: () => {
                console.log('success');
                refresh();
                notify('Produits supprimés avec succés');
                setShowDialog(false);
            },
            onFailure: error => notify(error.message, 'warning'),
        }
    );
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                label="resources.products.actions.delete_bulk"
            >
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Supprimer des produits"
            >
                <DialogTitle>Suppression des produits</DialogTitle>
                <DialogContent>
                    êtes vous sùre de vouloir supprimer les produits
                    séléctionnés?
                </DialogContent>
                <DialogActions>
                    <Button
                        label="pos.actions.concel"
                        disabled={loading}
                        onClick={handleCloseClick}
                    >
                        <IconCancel />
                    </Button>
                    <Button
                        label="resources.products.actions.delete_bulk"
                        disabled={loading}
                        onClick={updateMany}
                    >
                        <DeleteIcon />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BulkDeleteButton;
