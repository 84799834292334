import React from 'react';
import {
    Datagrid,
    ReferenceField,
    DateField,
    TextField,
    Filter,
    List,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

const QrCodePrintFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput
            source="product"
            label="resources.qrcodes.fields.product"
            reference="products"
            alwaysOn
        >
            <AutocompleteInput optionText="title" emptyText="Tout" resettable />
        </ReferenceInput>
    </Filter>
);
const QrCodesPrintList = (props: any) => {
    return (
        <List
            tltle="ra"
            {...props}
            hasCreate={false}
            filters={<QrCodePrintFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick={false}>
                <ReferenceField
                    label="resources.scans.fields.product"
                    source="product"
                    reference="products"
                >
                    <TextField source="title" />
                </ReferenceField>
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.qrcodes.fields.created_at"
                    showTime
                />
            </Datagrid>
        </List>
    );
};

export default QrCodesPrintList;
