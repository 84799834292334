import AddShoppingCart from '@material-ui/icons/AddShoppingCart';

import List from './ProductList';
import Edit from './ProductEdit';
import Create from './ProductCreate';
import Show from './ProductsShow';

export default {
    list: List,
    show: Show,
    edit: Edit,
    create: Create,
    icon: AddShoppingCart,
};
