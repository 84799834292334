import React from 'react';
import {
    Datagrid,
    ReferenceField,
    DateField,
    TextField,
    Filter,
    List,
    ReferenceInput,
    AutocompleteInput,
    useRecordContext,
} from 'react-admin';
import FullNameField from '../fields/users/FullNameField';
import UseCouponBtn from '../coupons-users/btns/UseBtn';
import CountryInput from '../inputs/CountryInput';
const CouponsUsersFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput
            source="user"
            label="resources.coupons.fields.user"
            reference="users"
            alwaysOn
        >
            <AutocompleteInput optionText="name" emptyText="Tout" resettable />
        </ReferenceInput>
        <CountryInput
            source="user_info.country"
            label="resources.users.fields.country"
            alwaysOn
        />
    </Filter>
);
const CouponUsersList = (props: any) => {
    const record = useRecordContext(props);
    return (
        <List
            tltle="ra"
            {...props}
            basePath="/coupons-users"
            resource="coupons-users"
            hasCreate={false}
            filters={<CouponsUsersFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            filter={{ coupon: record.id }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick={false}>
                <TextField
                    source="code"
                    label="resources.coupons_users.fields.code"
                />
                <ReferenceField
                    label="resources.coupons_users.fields.user"
                    source="user"
                    reference="users"
                >
                    <FullNameField />
                </ReferenceField>

                <TextField
                    source="user_info.country"
                    label="resources.users.fields.country"
                />
                <DateField
                    source="used_at"
                    type="date"
                    label="resources.coupons_users.fields.used_at"
                    showTime
                />
                <UseCouponBtn />
            </Datagrid>
        </List>
    );
};

export default CouponUsersList;
