import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    Filter,
    List,
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import FullNameField from '../fields/users/FullNameField';
import UseCouponBtn from './btns/UseBtn';

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'coupon', 'coupons').then(coupons => {
        fetchRelatedRecords(records, 'user', 'users').then(users => {
            const data = records.map(record => ({
                code: record.code,
                client: users[record.user].name,
                coupon: coupons[record.coupon].title,
                utilise: record.is_used ? 'Oui' : 'Non',
                utilise_le: record.is_used
                    ? new Date(record.used_at).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                      })
                    : '',
            }));
            return jsonExport(
                data,
                {
                    headers: [
                        'code',
                        'client',
                        'coupon',
                        'utilise',
                        'utilise_le',
                    ],
                },
                (err, csv) => {
                    downloadCSV(csv, 'gains');
                }
            );
        });
    });
};

const CouponFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput
            source="user"
            label="resources.coupons_users.fields.user"
            reference="users"
            alwaysOn
        >
            <AutocompleteInput optionText="name" emptyText="Tout" resettable />
        </ReferenceInput>
        <ReferenceInput
            source="coupon"
            label="resources.coupons_users.fields.coupon"
            reference="coupons"
            alwaysOn
        >
            <AutocompleteInput optionText="title" emptyText="Tout" resettable />
        </ReferenceInput>
    </Filter>
);
const CategoryList = (props: any) => {
    return (
        <List
            {...props}
            filters={<CouponFilter />}
            sort={{ field: 'creaetd_at', order: 'DESC' }}
            perPage={10}
            filter={{ is_used: true }}
            exporter={exporter}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick={false}>
                <TextField
                    source="code"
                    label="resources.coupons_users.fields.code"
                />
                <ReferenceField
                    label="resources.coupons_users.fields.user"
                    source="user"
                    reference="users"
                >
                    <FullNameField />
                </ReferenceField>
                <ReferenceField
                    label="resources.coupons_users.fields.coupon"
                    source="coupon"
                    reference="coupons"
                    link="show"
                >
                    <TextField source="title" />
                </ReferenceField>
                <DateField
                    source="used_at"
                    type="date"
                    label="resources.coupons_users.fields.used_at"
                    showTime
                />
                <UseCouponBtn />
            </Datagrid>
        </List>
    );
};

export default CategoryList;
