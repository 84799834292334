import React, { FC } from 'react';
import {
    Show,
    DateField,
    TextField,
    FunctionField,
    TopToolbar,
    EditButton,
    DeleteWithConfirmButton,
} from 'react-admin';

import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Coupon, FieldProps } from '../types';
import CouponUsersList from './CouponUsersList';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';
import Thumbnailfield from '../fields/common/ThumbnailField';

const useStyles = makeStyles(theme => ({
    coupon_description_section: {
        padding: '10px',
    },
    caracteristics_element: {
        width: '100%',
    },
}));

const CouponTitle: FC<FieldProps<Coupon>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const CouponShowActions: any = ({ basePath, data, resource }) => {
    return data ? (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            <DeleteWithConfirmButton
                confirmTitle={'Supprimer Coupon'}
                basePath={basePath}
                record={data}
            />
        </TopToolbar>
    ) : null;
};
/**
 * Show Component
 */

const CouponShow = (props: any) => {
    const classes = useStyles({});

    return (
        <Show
            {...props}
            component="div"
            tltle={<CouponTitle />}
            actions={<CouponShowActions />}
        >
            <BoxedShowLayout>
                <RaBox display="flex" flexWrap="wrap">
                    <RaBox
                        display="flex"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        className={classes.coupon_description_section}
                    >
                        <Thumbnailfield size="100" />
                    </RaBox>
                    <RaBox>
                        <FunctionField
                            addLabel={false}
                            className={classes.caracteristics_element}
                            render={record => (
                                <Typography variant="h4">
                                    {record ? record.title : null}
                                </Typography>
                            )}
                        />

                        <TextField
                            className={classes.caracteristics_element}
                            source="description"
                            addLabel={false}
                        />
                        <RaBox
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            className={classes.coupon_description_section}
                        >
                            <ColoredBooleanField
                                source="is_enabled"
                                addLabel={true}
                                label="resources.coupons.fields.is_enabled"
                            />
                            <DateField
                                source="created_at"
                                addLabel={true}
                                label="resources.coupons.fields.created_at"
                            />
                        </RaBox>
                    </RaBox>
                </RaBox>
                <CouponUsersList {...props} />
            </BoxedShowLayout>
        </Show>
    );
};

export default CouponShow;
