import CardGiftcard from '@material-ui/icons/CardGiftcard';

import List from './CouponList';
import Edit from './CouponEdit';
import Create from './CouponCreate';
import Show from './CouponShow';

export default {
    list: List,
    show: Show,
    edit: Edit,
    create: Create,
    icon: CardGiftcard,
};
