import React, { useState } from 'react';
import { Button, useRefresh, useDataProvider } from 'react-admin';
import PrintIcon from '@material-ui/icons/Print';

function QrCodesPrintBtn({ productId }) {
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const handleClick = () => {
        setLoading(true);
        dataProvider
            .create('qrcodes/print', { data: { productId: productId } })
            .then(({ json }) => {
                console.log(json);
                fetch(json.download_url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                })
                    .then(response => response.blob())
                    .then(blob => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                            new Blob([blob])
                        );
                        const link = document.createElement('a');
                        link.setAttribute(
                            'download',
                            `qrcodes-${json._id}.pdf`
                        );
                        if (link) {
                            link.href = url;

                            // Append to html link element page
                            document.body.appendChild(link);

                            // Start download
                            link.click();

                            // Clean up and remove the link
                            if (link.parentNode)
                                link.parentNode.removeChild(link);
                        }

                        setLoading(false);
                        refresh();
                    });
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    };
    if (error) {
        return <p>ERROR</p>;
    }
    return (
        <>
            <Button
                disabled={loading}
                onClick={handleClick}
                label="resources.products.actions.print_qrcodes"
            >
                <PrintIcon />
            </Button>
        </>
    );
}

export default QrCodesPrintBtn;
