import React, { FC, useState, useCallback } from 'react';
import {
    Show,
    ReferenceField,
    DateField,
    TextField,
    FunctionField,
    TopToolbar,
    EditButton,
    DeleteWithConfirmButton,
    SimpleForm,
} from 'react-admin';

import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Product, FieldProps } from '../types';
import Aside from './Aside';
import ProductQrCodeList from './ProductQrCodesList';

import ColoredBooleanField from '../fields/common/ColoredBooleanField';
import Thumbnailfield from '../fields/common/ThumbnailField';

import QrCodeGenerateButton from './btns/QrCodeGenerateButton';
import QrCodesPrintBtn from './btns/QrCodesPrintBtn';

const useStyles = makeStyles(theme => ({
    product_description_section: {
        padding: '10px',
    },
    caracteristics_element: {
        width: '100%',
    },
}));

const ProductTitle: FC<FieldProps<Product>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const ProductShowActions: any = ({ basePath, data, resource }) => {
    return data ? (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            <DeleteWithConfirmButton basePath={basePath} record={data} />
        </TopToolbar>
    ) : null;
};
/**
 * Show Component
 */
const GenerateQrCodesForm = (props: any) => {
    const { id: product_id, no_printed_qrcodes } = props.record;
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);
    const PrintBtn = no_printed_qrcodes ? (
        <QrCodesPrintBtn productId={product_id} />
    ) : null;
    return (
        <>
            <QrCodeGenerateButton
                onChange={handleChange}
                productId={product_id}
            />
            {PrintBtn}
        </>
    );
};
const ProductShow = (props: any) => {
    const classes = useStyles({});

    return (
        <Show
            {...props}
            component="div"
            tltle={<ProductTitle />}
            actions={<ProductShowActions />}
            aside={<Aside />}
        >
            <BoxedShowLayout>
                <RaBox display="flex" flexWrap="wrap">
                    <RaBox
                        display="flex"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        className={classes.product_description_section}
                    >
                        <Thumbnailfield size="100" />
                    </RaBox>
                    <RaBox>
                        <FunctionField
                            addLabel={false}
                            className={classes.caracteristics_element}
                            render={record => (
                                <Typography variant="h4">
                                    {record ? record.title : null}
                                </Typography>
                            )}
                        />

                        <TextField
                            className={classes.caracteristics_element}
                            source="description"
                            addLabel={false}
                            label="resources.products.fields.description"
                        />
                        <SimpleForm toolbar={false}>
                            <GenerateQrCodesForm />
                        </SimpleForm>
                        <RaBox
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            className={classes.product_description_section}
                        >
                            <ColoredBooleanField
                                source="is_published"
                                addLabel={true}
                                label="resources.products.fields.is_published"
                            />
                            <DateField
                                source="created_at"
                                addLabel={true}
                                label="resources.products.fields.created_at"
                            />
                            <ReferenceField
                                label="resources.products.fields.category"
                                source="category"
                                reference="categories"
                            >
                                <TextField source="title" />
                            </ReferenceField>
                        </RaBox>
                    </RaBox>
                </RaBox>
                <ProductQrCodeList {...props} />
            </BoxedShowLayout>
        </Show>
    );
};

export default ProductShow;
