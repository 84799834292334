import React, { useState, useEffect, useCallback, FC } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

import NbSubscriptions from './NbSubscriptions';
import NbProducts from './NbProducts';
import NbCoupons from './NbCoupons';

interface State {
    nbSubscriptions?: number;
    nbProducts?: number;
    nbCoupons?: number;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    sectiontitle: { fontSize: 14 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();

    const fetchUsers = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };
        const { total: nbSubscriptions } = await dataProvider.getList(
            'users',
            query
        );

        setState(state => ({
            ...state,
            nbSubscriptions,
        }));
    }, [dataProvider]);

    const fetchProducts = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: '_id',
        };
        const { total: nbProducts } = await dataProvider.getList(
            'products',
            query
        );

        setState(state => ({
            ...state,
            nbProducts,
        }));
    }, [dataProvider]);

    const fetchCoupons = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: '_id',
        };
        const { total: nbCoupons } = await dataProvider.getList(
            'coupons',
            query
        );

        setState(state => ({
            ...state,
            nbCoupons,
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchUsers();
        fetchProducts();
        fetchCoupons();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/*<Welcome />*/}
            <Card>
                <CardContent>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.title"
                            value={state.nbSubscriptions}
                        />
                        <Spacer />
                        <NbProducts
                            label="pos.dashboard.products.title"
                            value={state.nbProducts}
                        />
                        <Spacer />
                        <NbCoupons
                            label="pos.dashboard.coupons.title"
                            value={state.nbCoupons}
                        />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
        </>
    );
};

export default Dashboard;
