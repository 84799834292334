import React, { useState } from 'react';
import {
    useCreate,
    useNotify,
    useRefresh,
    Button,
    useRecordContext,
} from 'react-admin';
import IconRemoveCircle from '@material-ui/icons/RemoveCircle';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function QrCodeUnScanButton() {
    const [showDialog, setShowDialog] = useState(false);
    const record = useRecordContext();
    const [create] = useCreate('qrcodes/' + record.id + '/unscan');
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async () => {
        create(
            { payload: { data: {} } },
            {
                onSuccess: ({ json }) => {
                    setShowDialog(false);
                    refresh();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                },
            }
        );
    };

    if (!record) return null;
    if (!record.is_scanned) return null;

    return (
        <>
            <Button
                onClick={handleClick}
                label="resources.products.actions.unscan_qrcode"
            >
                <IconRemoveCircle />
            </Button>
            <Dialog
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Dévalider le Scan"
            >
                <DialogTitle>Dévalider le Scan</DialogTitle>
                <DialogContent>
                    Si vous dévalidez le Scan de ce QrCode, le client perdera
                    ses points et le QrCode sera à nouveau disponible pour les
                    scans. <br />
                    <br />
                    <strong>
                        Attention! Cette opération est irréversible.
                    </strong>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} label="Fermer"></Button>
                    <Button onClick={handleSubmit} label="Dévallider"></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default QrCodeUnScanButton;
