import React from 'react';
import { Button, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function ExportPrintCsvBtn(props) {
    const handleClick = event => {
        event.preventDefault();
        const { record } = props;
        const {qrcodes} = record;
        const qrcodesIds = qrcodes.map(qrcode => {
            return {qrcode: qrcode};
        });
        jsonExport(qrcodesIds, {
            headers: ['qrcode'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'qrcodes_' + record._id); // download as 'qrcodes.csv` file
        });
    };
    return (
        <>
            <Button onClick={handleClick} label="CSV">
                <CloudDownloadIcon />
            </Button>
        </>
    );
}

export default ExportPrintCsvBtn;
