import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    ReferenceField,
    Filter,
    List,
    ReferenceInput,
    SearchInput,
    AutocompleteInput,
    EditButton,
    DeleteButton,
} from 'react-admin';
import Thumbnailfield from '../fields/common/ThumbnailField';
import PriceField from '../fields/common/PriceField';
import PrintAndDownloadOneQrCodeBtn from './btns/PrintAndDownloadOneQrCodeBtn';

const PcFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput
            source="category"
            label="resources.products.fields.category"
            reference="categories"
            alwaysOn
        >
            <AutocompleteInput optionText="title" emptyText="Tout" resettable />
        </ReferenceInput>
    </Filter>
);
const CategoryList = (props: any) => {
    return (
        <List
            {...props}
            filters={<PcFilter />}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="show">
                <Thumbnailfield source="image" />
                <TextField
                    source="title"
                    label="resources.products.fields.title"
                />
                <ReferenceField
                    source="category"
                    label="resources.products.fields.category"
                    reference="categories"
                >
                    <TextField source="title" />
                </ReferenceField>
                <PriceField
                    source="price"
                    label="resources.products.fields.price"
                />
                <NumberField
                    source="points"
                    label="resources.products.fields.points"
                />

                <DateField
                    source="created_at"
                    type="date"
                    label="resources.products.fields.created_at"
                    showTime
                />
                <PrintAndDownloadOneQrCodeBtn />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default CategoryList;
