import CropFreeIcon from '@material-ui/icons/CropFree';

import List from './QrCodeList';
import Show from './QrCodeShow';

export default {
    list: List,
    show: Show,
    icon: CropFreeIcon,
};
