import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    Filter,
    List,
    SearchInput,
    EditButton,
    DeleteWithConfirmButton,
} from 'react-admin';
import Thumbnailfield from '../fields/common/ThumbnailField';
import ColoredBooleanField from '../fields/common/ColoredBooleanField';
const CouponFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
const CouponList = (props: any) => {
    return (
        <List
            {...props}
            filters={<CouponFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="show">
                <Thumbnailfield source="image" />
                <TextField
                    source="title"
                    label="resources.coupons.fields.title"
                />
                <NumberField
                    source="points"
                    label="resources.coupons.fields.points"
                />
                <ColoredBooleanField
                    source="is_enabled"
                    label="resources.coupons.fields.is_enabled"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.coupons.fields.created_at"
                />
                <EditButton />
                <DeleteWithConfirmButton confirmTitle={'Supprimer Coupon'} />
            </Datagrid>
        </List>
    );
};

export default CouponList;
