import React from 'react';
import { Button } from 'react-admin';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const API_URL = process.env.REACT_APP_ADMIN_REST_API_URL;

function PrintAndDownloadOneQrCodeBtn(props) {
    const { record } = props;
    const handleClick = event => {
        event.preventDefault();
        console.log(record);
        fetch(`${API_URL}/qrcodes/${record.id}/print`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then(response => response.blob())
            .then(blob => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.setAttribute('download', `qrcodes-${record._id}.pdf`);
                if (link) {
                    link.href = url;

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    if (link.parentNode) link.parentNode.removeChild(link);
                }
            });
    };
    const label = record.is_printed ? 'Réimprimer' : 'Imprimer';
    return (
        <>
            <Button onClick={handleClick} label={label}>
                <CloudDownloadIcon />
            </Button>
        </>
    );
}

export default PrintAndDownloadOneQrCodeBtn;
