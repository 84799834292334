import * as React from 'react';
import { FC } from 'react';
import { Edit, TextField } from 'react-admin';
import { Product, FieldProps } from '../types';
import Aside from './Aside';
import ProductForm from './ProductForm';
const ProductEdit = (props: any) => {
    return (
        <Edit
            title={<ProductTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <ProductForm />
        </Edit>
    );
};

const ProductTitle: FC<FieldProps<Product>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

export default ProductEdit;
