import * as React from 'react';
import { FC } from 'react';
import { Customer, FieldProps } from '../../types';

const API_URL = process.env.REACT_APP_REST_API_URL;
interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const Thumbnailfield: FC<Props> = ({
    record,
    source = 'image',
    size = '25',
    className,
}) =>
    record ? (
        <img
            alt=""
            src={`${API_URL}/files/${record[source]}/view?size=${size}x${size}`}
            style={{ height: parseInt(size, 16) }}
            className={className}
        />
    ) : null;

export default Thumbnailfield;
