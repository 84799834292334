import * as React from 'react';
import { FC } from 'react';
import { Show, DateField, TextField } from 'react-admin';

import { RaBox, BoxedShowLayout } from 'ra-compact-ui';

import { Typography } from '@material-ui/core';
import { Coupon, FieldProps } from '../types';

const Title: FC<FieldProps<Coupon>> = ({ record }) =>
    record ? <Typography>Produit</Typography> : null;

/**
 * Show Component
 */
const CouponShow = (props: any) => {
    return (
        <Show {...props} component="div" tltle={<Title />}>
            <BoxedShowLayout>
                <RaBox display="flex" flexWrap="wrap">
                    <TextField
                        addLabel={true}
                        label="resources.coupons.fields.title"
                    />
                    <DateField
                        source="created_at"
                        addLabel={true}
                        label="resources.coupons.fields.created_at"
                    />
                </RaBox>
            </BoxedShowLayout>
        </Show>
    );
};

export default CouponShow;
